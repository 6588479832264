/* @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"; */
@import "../../node_modules/rsuite/dist/rsuite.css";
/* @import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"; */
/* @import "colors.css"; */
@import "rsuite-common.css";
@import "test-index.css";

body {
  /* overflow: hidden; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


