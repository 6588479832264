/* BODY */
.override-panel-layout {
    background: var(--color-panel-bg);
}

/* HEADER */
.override-menu-sidebar {
    background: var(--color-sidebar-bg);
}
.override-menu-sidebar a.rs-sidenav-item  {
    color: var(--color-sidebar-color);
}

.override-menu-sidebar a.rs-sidenav-item:hover  {
    background-color: var(--color-sidebar-bg-active);
    color: var(--color-sidebar-color);
}

.override-menu-sidebar .rs-dropdown.rs-dropdown-expand a.rs-dropdown-toggle {
    background-color: var(--color-sidebar-bg-active);
}

.override-menu-sidebar ul.rs-dropdown-menu li a.rs-dropdown-item {
    color: var(--color-sidebar-color);
}

.override-menu-sidebar ul.rs-dropdown-menu li a.rs-dropdown-item:hover {
    background-color: var(--color-sidebar-bg-hover);
    color: var(--color-sidebar-color);
}

.override-menu-sidebar ul.rs-dropdown-menu li a.rs-dropdown-item.rs-dropdown-item {
    padding-left: 70px;
}

.override-menu-sidebar ul.rs-dropdown-menu li a.rs-dropdown-item.rs-dropdown-item-active {
    color: var(--color-sidebar-submenu-active);
    background-color: var(--color-sidebar-submenu-bg-active);
    font-weight: 600;
}

.override-menu-sidebar a.rs-sidenav-item.rs-sidenav-item-active  {
    color: var(--color-sidebar-color-active);
    background-color: var(--color-sidebar-submenu-bg-active);
    font-weight: 600;
}

.override-menu-sidebar a.rs-dropdown-toggle.rs-dropdown-toggle-no-caret.rs-sidenav-item {
    background-color: inherit;
}


.override-menu-sidebar .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: inherit;
}
.override-menu-sidebar a.rs-dropdown-toggle.rs-sidenav-item:hover {
    background-color: inherit;
}

/* .override-user-info {

} */

.override-user-info .info-line{
    color: var(--color-sidebar-color)
}

.override-user-info .info-line.balance {
    display: flex;
    flex-direction: column;
}

.override-user-info .info-line.balance .currency-header {
    margin-bottom: 10px;
}
.override-user-info .info-line.balance > .currency-line {
    padding-left: 10px;
    margin-bottom: 10px;
}
.override-user-info .info-line.balance > .currency-line:last-child {
    padding-left: 10px;
    margin-bottom: 0;
}


/* RSUITE STYLES OVERLAP */

button.rs-btn.rs-btn-primary {
    color: var(--color-button-text-default) !important ;
    background-color: var(--color-button-bg-default) !important;
    border-color: var(--color-button-bg-default) !important;
    
}

div .rs-btn-primary:active:hover,
div .rs-btn-primary.rs-btn-active:hover,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
div .rs-btn-primary:active:focus,
div .rs-btn-primary.rs-btn-active:focus,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
div .rs-btn-primary:active.focus,
div .rs-btn-primary.rs-btn-active.focus,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: var(--color-button-text-default) !important;
    background-color: var( --color-button-bg-default) !important;
    border-color: var( --color-button-bg-default) !important;
    
}

a.rs-btn-ghost {
    color: var(--color-button-bg-default) !important;
    background-color: transparent;
    border-color: var(--color-button-bg-default) !important;
    box-shadow: none;
}

a.rs-btn-primary {
    color: var(--color-button-text-default) !important;
    background-color: var( --color-button-bg-default) !important;
    border-color: var( --color-button-bg-default) !important;    
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: var(--color-calendar-bg-active) !important;
}

.rs-picker-menu .rs-calendar-table-cell.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border-color: var(--color-calendar-today-border-color) !important;
    box-shadow: inset 0 0 0 1px var(--color-calendar-today-border-color) !important;
}